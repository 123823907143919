<template>
  <v-card
    :loading="loading"
    max-width="400"
  >
    <v-list class="transparent" v-if="storageInfo">
      <v-list-item>
        <v-list-item-title>
          {{'data.storage_info.photo'| t}}
        </v-list-item-title>

        <v-list-item-subtitle class="text-right">
          {{getSize(storageInfo.photo)}}
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-title>
          {{'data.storage_info.file'| t}}
        </v-list-item-title>

        <v-list-item-subtitle class="text-right">
          {{getSize(storageInfo.file)}}
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-title>
          {{'data.total'| t}}
        </v-list-item-title>

        <v-list-item-subtitle class="text-right">
          {{getSize(totalBytes)}}
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-list-item-title>
          {{'data.storage_info.max_gb'| t}}
        </v-list-item-title>

        <v-list-item-subtitle class="text-right">
          {{getSize(maxBytes)}} ({{usedPercentage}}%)
        </v-list-item-subtitle>
      </v-list-item>

      <v-list-item>
        <v-progress-linear
          color="primary lighten-1"
          :value="usedPercentage"
        ></v-progress-linear>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="babel" type="text/babel">
import prettyBytes from 'pretty-bytes'
export default {
  data: () => ({
    storageInfo: null,
    loading: false,
  }),
  created() {
    this.init()
  },
  methods: {
    async init() {
      this.loading = true
      try {
        this.storageInfo = await this.$api.collection.baseApi.storageInfo()
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    getSize(size) {
      return prettyBytes(size)
    },
  },
  computed: {
    totalBytes() {
      if(!this.storageInfo) return 0
      return this.storageInfo.total
    },
    maxBytes() {
      if(!this.storageInfo) return 0
      const max = parseInt(this.storageInfo.storage_max_gb)
      if(isNaN(max)) return 0
      return max*1000*1000*1000
    },
    usedPercentage() {
      if(!this.maxBytes) return 0
      const result = (this.totalBytes/this.maxBytes)*100
      return window.eagleLodash.round(result, 2)
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
