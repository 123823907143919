import { render, staticRenderFns } from "./storage.vue?vue&type=template&id=1071f2b1&scoped=true&"
import script from "./storage.vue?vue&type=script&lang=babel&"
export * from "./storage.vue?vue&type=script&lang=babel&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1071f2b1",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
installComponents(component, {VCard,VList,VListItem,VListItemSubtitle,VListItemTitle,VProgressLinear})
